import Welcome from "./Welcome";
import About from "./About";
import Skills from "./Skills";
import Qualifications from "./Qualifications";

const Profile = (props) => {
  return (
    <>
      {props.project === 0 ? <Welcome project={props.project} /> : null}
      {props.project === 1 ? (
        <About project={props.project} onClickHandler={props.onClickHandler} rotateBg={props.rotateBg} />
      ) : null}
      {props.project === 2 ? <Skills project={props.project} /> : null}
      {props.project === 3 ? <Qualifications project={props.project} /> : null}
    </>
  );
};

export default Profile;
