import { motion } from "framer-motion";
import classes from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={classes.bg}>
      <div className={classes.container}>
        <p>Loading</p>

        <motion.div
          animate={{ opacity: [0, 1, 0, 0, 0] }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          .
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 1, 0, 0, 0] }}
          transition={{ repeat: Infinity, duration: 2, delay: 0.66 }}
        >
          .
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 1, 0, 0, 0] }}
          transition={{ repeat: Infinity, duration: 2, delay: 1.33 }}
        >
          .
        </motion.div>
      </div>
    </div>
  );
};

export default Loading;
