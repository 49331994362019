import { AnimatePresence, motion } from "framer-motion";

import classes from './Links.module.css'

const Links = (props) => {
    
      const listItems = props.links.map(
        (currentLink, index) =>
          currentLink.title !== "Welcome" && (
            <motion.li
              className={classes.links}
              key={`li${index}`}
              onClick={props.disable ? null : props.onClickHandler}
              value={index}
              title={props.title}
              whileHover={{ letterSpacing: "1px" }}
              style={{
                textTransform: "Capitalize",
                color: props.currentLink === index ? "white" : null,
                fontStyle: currentLink.inProgress === true ? "italic" : null,
              }}
            >
              <AnimatePresence>
                {index === props.currentLink && props.areaSelected && (
                  <motion.div
                    className={classes.active}
                    layoutId="active"
                    key={`active${index}`}
                    transition={{ type: "spring", stiffness: 50 }}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                  ></motion.div>
                )}
              </AnimatePresence>
              {currentLink.title}
            </motion.li>
          )
      );
  return (
    <>
      <h1>{props.title}</h1>
      <ul>{listItems}</ul>
    </>
  );
};

export default Links;
