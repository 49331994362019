import { useEffect, useState } from "react";

import Loading from "./components/Loading";
import Intro from "./components/Intro";
import MainPage from "./components/MainPage";

import classes from "./App.module.css";

function App() {
  const [introPlaying, setIntroPlaying] = useState(true);
  const [imagesCached, setImagesCached] = useState(false);

  useEffect(() => {
    if (imagesCached) {
      setTimeout(() => {
        setIntroPlaying(false);
      }, 3500);
    }
  }, [imagesCached]);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);

    setImagesCached(true);
  };

  useEffect(() => {
    const imgs = [
      "../assets/images/dry-drops-sm.jpg",
      "../assets/images/dry-drops-lg.jpg",
      "../assets/images/eat-me-sm.jpg",
      "../assets/images/eat-me-lg.jpg",
      "../assets/images/colour-type-sm.jpg",
      "../assets/images/colour-type-lg.jpg",
      "../assets/images/fruit-hunter-sm.jpg",
      "../assets/images/fruit-hunter-lg.jpg",
    ];

    cacheImages(imgs);
  });

  return (
    <>
      {imagesCached ? (
        <div>
          {introPlaying && <Intro />}
          <div className={classes.main}>{!introPlaying && <MainPage />}</div>
        </div>
      ) : <Loading />
      }
    </>
  );
}

export default App;
