import { motion } from "framer-motion";

import Project from "./Project";
import Profile from "./Profile";

import classes from "./LeftSection.module.css";

const LeftSection = (props) => {
  const container = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.div
      className={classes.leftSection}
      variants={container}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ type: "spring", bounce: 0.2 }}
      key={`leftSection${props.project}`}
    >
      {props.showingProject ? (
        <Project projects={props.projects} project={props.project} />
      ) : (
        <Profile
          project={props.project}
          rotateBg={props.rotateBg}
        />
      )}
    </motion.div>
  );
};

export default LeftSection;
