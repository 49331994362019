import { motion } from "framer-motion";

import useWindowResize from "../hooks/useWindowResize";

import classes from "./Project.module.css";

const Project = (props) => {
  const size = useWindowResize();

  const overlay = {
    initial: {
      opacity: 0,
    },
    hover: {
      opacity: 1,
    },
  };

  const siteLink = {
    initial: {
      opacity: 0,
      y: 20,
    },
    hover: {
      opacity: 1,
      y: 0,
      transition: { delay: 0.3, duration: 0.5, ease: "easeOut" },
    },
  };

  const item = {
    initial: { x: size.width > 768 ? -800 : -400, opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { type: "spring", bounce: 0.2 } },
    exit: { x: size.width > 768 ? -800 : -400, opacity: 0 },
  };

  const line = {
    initial: { scale: 0 },
    animate: { scale: 1, transition: { delay: 0.7, duration: 0.3 } },
    exit: { x: size.width > 768 ? -800 : -400, opacity: 0 },
  };

  const desc = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.7, duration: 0.3 } },
    exit: { x: size.width > 768 ? -800 : -400, opacity: 0 },
  };

  return (
    <>
      <motion.h1
        variants={item}
        style={{
          fontSize:
            props.project === 2 || props.project === 3 || props.project === 4 ? "2rem" : "3rem",
          paddingTop: props.project === 2 ? ".5rem" : null,
          fontFamily: props.projects[props.project].font,
          fontWeight: props.project === 3 || props.project === 1 ? "100" : 600,
          textTransform: props.project !== 1 ? "uppercase" : null,
        }}
      >
        {props.projects[props.project].title}
      </motion.h1>

      <motion.div className={classes.imageWrapper} variants={item}>
        <a
          href={props.projects[props.project].website}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={props.projects[props.project].imageSm}
            srcSet={` ${props.projects[props.project].imageSm} 600w, ${
              props.projects[props.project].imageLg
            } 800w`}
            className={classes.image}
            alt={props.projects[props.project].title}
          />
          {props.projects[props.project].website && (
            <motion.div
              className={classes.imageOverlay}
              variants={overlay}
              initial="initial"
              whileHover="hover"
              exit="exit"
            >
              <motion.p variants={siteLink}>View Site</motion.p>
            </motion.div>
          )}
        </a>
      </motion.div>

      <div className={classes.leftWrapper}>
        <motion.div
          key={`line${props.project}`}
          className={classes.line}
          variants={line}
        ></motion.div>

        <motion.div key={`desc${props.project}`} variants={desc}>
          <p>{props.projects[props.project].description}</p>
          <p>{props.projects[props.project].descriptionTwo}</p>

          <ul className={classes.links}>
            <li>
              <a
                href={props.projects[props.project].gitHub}
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </li>
            {props.projects[props.project].website && (
              <li>
                <a
                  href={props.projects[props.project].website}
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </li>
            )}
          </ul>
        </motion.div>
      </div>
    </>
  );
};

export default Project;
