import { motion } from "framer-motion";

import classes from "./skills.module.css";

const Skills = () => {
  const languages = [
    {
      language: "HTML",
      percentage: 90,
    },
    {
      language: "CSS",
      percentage: 85,
    },
    {
      language: "Javascript",
      percentage: 80,
    },
    {
      language: "Python",
      percentage: 80,
    },
    {
      language: "C#",
      percentage: 70,
    },
    {
      language: "React",
      percentage: 80,
    },
    {
      language: "Django",
      percentage: 80,
    },
    {
      language: "Bootstrap",
      percentage: 85,
    },
    {
      language: "JQuery",
      percentage: 80,
    },
    {
      language: "SQL",
      percentage: 70,
    },
    {
      language: "Node",
      percentage: 60,
    },
  ];

  const colors = [
    `linear-gradient(45deg, rgb(11, 72, 107) 0%, rgb(245,98,23) 100%)`,
    ` linear-gradient(45deg, rgb(45,22,84) 0%, rgb(153,117,189) 100%)`,
    `linear-gradient(45deg, rgb(53,92,125) 0%,  rgb(192,108,132) 100%)`,
    `linear-gradient(45deg, rgba(19,78,94,1) 0%, rgba(113,178,128,1) 100%)`,
  ];

  const getRandomNum = (max) => {
    return Math.floor(Math.random() * max)
  }

  const randomNo = getRandomNum(4)


  const dataItems = languages.map((language, index) => (
    <div className={classes.wrapper} key={`skillRow${index}`}>
      <motion.div initial={{width: 0}} animate={{width: `${language.percentage}%`, background: colors[randomNo]}} transition={{type: "spring", duration: 2, delay: index/10,}} className={classes.bar}>
        <h2>{language.language}</h2>
      </motion.div>
    </div>
  ));

  return <div className={classes.container}>{dataItems}</div>;
};

export default Skills;
