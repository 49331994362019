import { useState } from "react";
import { motion } from "framer-motion";

import AboutHeaders from "./AboutHeaders";

import classes from "./About.module.css";

const About = (props) => {
  const [selection, setSelection] = useState(0);

  const pastDesc = (
    <p>
      For as long as I can remember I have always had a burning passion for all
      things tech, from computer games to gadgets to the world wide web.{" "}
      <br></br>
      <br className={classes.displayNone}></br>
      <span className={classes.tab} />
      In 2021 I decided to follow my dreams and seek to align my interests with
      my work life. I enrolled into a university certified diploma in Full Stack
      Software Development with{" "}
      <a href="https://codeinstitute.net/" target="_blank" rel="noreferrer">
        The Code Institute
      </a>{" "}
      and I never looked back.
    </p>
  );

  const presentDesc = (
    <p>
      I received my{" "}
      <a
        href="https://www.credential.net/4a16bce4-b545-4474-ab37-2837d4eb56b1#gs.1kl7z4"
        target="_blank"
        rel="noreferrer"
      >
        Diploma in Full Stack Software Development
      </a>{" "}
      in 2022 where I achieved a Distinction grade. Since then I have been
      furthering my knowledge with various online courses and building projects
      for fun. <br></br>
      <br className={classes.displayNone}></br>
      <span className={classes.tab} />I built this very website using the React
      library and have in the works a MERN stack project to add to my portfolio.
      I'm also learning C# and the Unity game engine so I can construct some
      games that have been floating around my head for years. On top of that I'm
      also planning on creating a full stack project using Microsoft's .Net
      framework.
    </p>
  );

  const futuretDesc = (
    <p>
      In the future I would love to put all my newly acquired skills to use and
      help grow not only a company but myself. I am always eager to learn more
      and look forward to any new opportunities the future may bring. <br></br>
      <br className={classes.displayNone}></br>
      <span className={classes.tab} />
      If you feel I may be suited for a role you have open or want to contact me
      in any way please feel free to do so{" "}
      <a href="mailto: derek.degoey@hotmail.co.uk">here.</a>
    </p>
  );

  const data = [
    {
      title: "Past",
      desc: pastDesc,
    },
    { title: "Present", desc: presentDesc },

    { title: "Future", desc: futuretDesc },
  ];

  const onClickHandler = (e) => {
    setSelection(e.target.value);
    props.rotateBg()
  };

  // const sliderChangeHandler = (index) => {
  //   setSelection(index);
  // };

  const headers = data.map((header, index) => (
    <li
      key={`li${index}`}
      className={index === selection ? classes.active : null}
      onClick={onClickHandler}
      value={index}
    >
      {header.title}
    </li>
  ));

  return (
    <div className={classes.container}>
     <div className={classes.headers}>
      <AboutHeaders items={headers} />
     </div>
      

      <motion.div
        className={classes.desc}
        key={selection}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        {data[selection].desc}
        {/* {"\u00A0"}bio coming soon... */}
      </motion.div>
    </div>
  );
};

export default About;
