import { motion } from "framer-motion";

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";

import classes from "./Footer.module.css";

const Footer = (props) => {
  const list = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      rotateY: 0,
    },
  };

  const listItem = {
    hidden: { opacity: 0, y: 100 },
    show: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <footer className={classes.footer}>
      <div>
        <motion.ul
          variants={list}
          className={classes.list}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <motion.li variants={listItem}>
            <a
              href="https://github.com/Delboy"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          </motion.li>
          <motion.li variants={listItem}>
            <a
              href="https://www.linkedin.com/in/derek-de-goey/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
          </motion.li>
          <motion.li variants={listItem}>
            <a href="mailto: derek.degoey@hotmail.co.uk">
              <FaEnvelope />
            </a>
          </motion.li>
          <motion.li variants={listItem} onClick={props.modalHandler}>
            <FaFileAlt />
          </motion.li>
        </motion.ul>
      </div>
    </footer>
  );
};

export default Footer;
