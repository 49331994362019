import { AnimatePresence, motion } from "framer-motion";

import dryDropsSm from "../assets/images/dry-drops-sm.jpg";
import eatMeSm from "../assets/images/eat-me-sm.jpg";
import colorTypeSm from "../assets/images/colour-type-sm.jpg";
import fruitHunterSm from "../assets/images/fruit-hunter-sm.jpg";
import dryDropsLg from "../assets/images/dry-drops-lg.jpg";
import eatMeLg from "../assets/images/eat-me-lg.jpg";
import colorTypeLg from "../assets/images/colour-type-lg.jpg";
import fruitHunterLg from "../assets/images/fruit-hunter-lg.jpg";
import pbbSm from '../assets/images/pbb-small.jpg';
import pbbLg from '../assets/images/pbb-large.jpg';

import useWindowResize from "../hooks/useWindowResize";

import LeftSection from "./LeftSection";
import RightSection from "./RightSection.js";

import classes from "./MainContent.module.css";

const MainContent = (props) => {
  const size = useWindowResize();

  const projects = [
    {
      title: "dry drops",
      description:
        "Dry Drops is an e-commerce website for alcohol-free beverages. It was built using the python based framework Django.",
      gitHub: "https://github.com/Delboy/DryDrops",
      website: "https://drydrops-production.up.railway.app/",
      languages: ["HTML", "CSS", "Javascript", "Python", "Django", "Bootstrap"],
      imageSm: dryDropsSm,
      imageLg: dryDropsLg,
      font: "'imbue', serif",
    },
    {
      title: "Eat Me!",
      description:
        "Eat me! is a website where users come together to share their favourite created recipes.",
      gitHub: "https://github.com/Delboy/EatMe",
      website: "https://eatme-production.up.railway.app/",
      languages: ["HTML", "CSS", "Python", "Django"],
      imageSm: eatMeSm,
      imageLg: eatMeLg,
      font: "'imbue', serif",
    },
    {
      title: "Colour Type",
      description:
        "Colour Type is a fun game that incorporates 'The Stroop Test' which shows our tendency to experience difficulty naming colors written in a different color.",
      gitHub: "https://github.com/Delboy/Colour-Type",
      website: "https://delboy.github.io/Colour-Type/",
      languages: ["HTML", "CSS", "Javascript"],
      imageSm: colorTypeSm,
      imageLg: colorTypeLg,
      font: "'Special Elite', cursive",
    },
    {
      title: "fruit hunter",
      description:
        "Fruit hunter is a game written in Python which runs on a mock terminal. User's try to find all the fruit by playing a hangman style game.",
      gitHub: "https://github.com/Delboy/Fruit-Hunter",
      website: "https://fruit-hunter.onrender.com/",
      languages: ["Python"],
      imageSm: fruitHunterSm,
      imageLg: fruitHunterLg,
      font: "'Sanchez', serif",
    },
    {
      title: "* PBB - In Progress *",
      description:
        "PBB (Plant Based Boutique) is a MERN stack e-commerce site selling plant based shoes and care products.",
      gitHub: "https://github.com/Delboy/PBB",
      
      languages: ["HTML", "CSS", "Javascript", "React", "Node", "Bootstrap", "Framer-Motion"],
      imageSm: pbbSm,
      imageLg: pbbLg,
      font: "'Sanchez', serif",
      inProgress: true
    },
  ];

  return (
    <motion.div
      className={classes.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1, duration: 0.5 }}
    >
      <AnimatePresence>
        <motion.div
          key={`cb${props.project}`}
          className={`${classes.cornerBorder} ${classes.cornerBottom}`}
          initial={{ x: size.width > 768 ? 800 : 400, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { delay: 0.9, type: "spring", bounce: 0.2 },
          }}
          exit={{ x: size.width > 768 ? 800 : 400, opacity: 0 }}
        ></motion.div>
        <motion.div
          key={`ct${props.project}`}
          className={`${classes.cornerBorder} ${classes.cornerTop}`}
          initial={{ x: size.width > 768 ? -800 : -400, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: { delay: 0.9, type: "spring", bounce: 0.2 },
          }}
          exit={{ x: size.width > 768 ? -800 : -400, opacity: 0 }}
        ></motion.div>
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <LeftSection
          projects={projects}
          project={props.project}
          showingProject={props.showingProject}
          rotateBg={props.rotateBg}
        />
      </AnimatePresence>
      <RightSection
        projects={projects}
        project={props.project}
        showingProject={props.showingProject}
        onClickHandler={props.onClickHandler}
      />
    </motion.div>
  );
};

export default MainContent;
