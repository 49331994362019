import { motion } from "framer-motion";

import useWindowResize from "../hooks/useWindowResize";

import classes from "./Welcome.module.css";

const Welcome = (props) => {
  const size = useWindowResize();

  const item = {
    initial: { x: size.width > 768 ? -800 : -400, opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { type: "spring", bounce: 0.2 } },
    exit: { x: size.width > 768 ? -800 : -400, opacity: 0 },
  };

  const line = {
    initial: { scale: 0 },
    animate: { scale: 1, transition: { delay: 0.7, duration: 0.3 } },
    exit: { x: size.width > 768 ? -800 : -400, opacity: 0 },
  };

  const desc = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.7, duration: 0.3 } },
    exit: { x: size.width > 768 ? -800 : -400, opacity: 0 },
  };

  return (
    <>
      <motion.h1
        variants={item}
        style={{
          fontSize: "8rem",
        }}
      >
        Hi
      </motion.h1>

      <div className={classes.leftWrapper}>
        <motion.div
          key={`line${props.project}`}
          className={classes.line}
          variants={line}
        ></motion.div>

        <motion.div key={`desc${props.project}`} variants={desc}>
          <p>I'm Derek de Goey, a developer from the UK. 
            When im not scratching my head over a 9x9 rubik's cube im busy coding my next brainstorm!
          </p>
          <p>
            On this website you will find a little about myself and a showcase
            of some of my work. Enjoy!
          </p>
        </motion.div>
      </div>
    </>
  );
};

export default Welcome;
