import Links from './Links'

import classes from './RightSection.module.css'

const RightSection = (props) => {

    const profileTitles = [
      {
        title: "Welcome"
      },
      {
        title: "About"
      },
      {
        title: "Skills"
      },
      {
        title: "Qualifications"
      },
    ]

    return (
        <div className={classes.rightSection}>
        <Links
          title="Profile"
          onClickHandler={props.onClickHandler}
          currentLink={props.project}
          links={profileTitles}
          areaSelected={!props.showingProject}
        />
        
        <Links
          title="Projects"
          onClickHandler={props.onClickHandler}
          currentLink={props.project}
          links={props.projects}
          disableFirst={true}
          areaSelected={props.showingProject}
        />

      </div>
    )
}

export default RightSection