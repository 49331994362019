import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "./Logo";
import MainContent from "./MainContent";
import Footer from "./Footer";
import Overlay from "./Overlay";
import Modal from "./Modal";

import useWindowResize from "../hooks/useWindowResize";

import classes from "./MainPage.module.css";

const MainPage = () => {
  const [project, setProject] = useState(0);
  const [showingProject, setShowingProject] = useState(false);
  const [rotate, setRotate] = useState(45);
  const [modalOpen, setModalOpen] = useState(false);

  const size = useWindowResize();

  const onClickHandler = (e) => {
    RotateBackground();

    if (e.target.title === "Projects") {
      setShowingProject(true);
    } else {
      setShowingProject(false);
    }

    projectSetter(e);
  };

  const RotateBackground = () => {
    setRotate((prevState) => prevState + 180);
  }

  const projectSetter = (e) => {
    if (size.width < 577) {
      scrollToTop();
    }

    if (e.target.value) {
      if (e.target.value === project) {
        setProject(0);
        setShowingProject(false);
        return;
      }
      setProject(e.target.value);
    } else {
      setProject(0);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 110, behavior: "smooth" });
  };

  const modalHandler = () => {
    setModalOpen(!modalOpen);
  };

  const colors = [
    `linear-gradient(${rotate}deg, rgb(51, 61, 77) 0%, rgb(34, 33, 37) 100%)`,
    `linear-gradient(${rotate}deg, rgb(11, 72, 107) 0%, rgb(245,98,23) 100%)`,
    ` linear-gradient(${rotate}deg, rgb(45,22,84) 0%, rgb(153,117,189) 100%)`,
    `linear-gradient(${rotate}deg, rgb(53,92,125) 0%,  rgb(192,108,132) 100%)`,
    `linear-gradient(${rotate}deg, rgba(19,78,94,1) 0%, rgba(113,178,128,1) 100%)`,
  ];

  return (
    <motion.div
      className={classes.mainPage}
      animate={{ background: showingProject ? colors[project + 1] : colors[0] }}
      transition={{ duration: 1.2 }}
    >
      <AnimatePresence>
        {modalOpen && (
          <>
            <Overlay modalHandler={modalHandler} open={modalOpen} />
            <Modal modalHandler={modalHandler} />
          </>
        )}
      </AnimatePresence>
      <Logo onClickHandler={onClickHandler} />
      <section className={classes.section}>
        <MainContent
          project={project}
          onClickHandler={onClickHandler}
          showingProject={showingProject}
          rotateBg={RotateBackground}
        />
        <Footer modalHandler={modalHandler} />
      </section>
    </motion.div>
  );
};

export default MainPage;
