import { motion} from "framer-motion";

import { FaFreeCodeCamp } from "react-icons/fa";
import { SiUdemy } from "react-icons/si";
import { FaRegCircle } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";

import classes from "./Qualifications.module.css";

const Qualifications = () => {
  const qualifications = [
    {
      organisation: "Code Institute",
      course: "Full Stack Diploma",
      
      icon: <FaRegCircle />,
      siteLink: "https://codeinstitute.net/",
      certLink:
        "https://www.credential.net/4a16bce4-b545-4474-ab37-2837d4eb56b1#gs.1kl7z4",
    },
    {
      organisation: "FreeCodeCamp",
      course: "Foundational C# with Microsoft",
      
      icon: <FaFreeCodeCamp />,
      siteLink: "https://www.freecodecamp.org",
      certLink:
        "https://www.freecodecamp.org/certification/delboy/foundational-c-sharp-with-microsoft",
    },
    {
      organisation: "Udemy",
      course: "React - The Complete Guide",
     
      icon: <SiUdemy />,
      siteLink: "https://www.udemy.com/",
      certLink:
        "https://www.udemy.com/certificate/UC-de001886-e145-45de-bd34-f5fa087a5102/?utm_source=sendgrid.com&utm_medium=email&utm_campaign=email",
    },
    {
      organisation: "Udemy",
      course: "Complete C# Unity Game Developer 2D",
      
      icon: <SiUdemy />,
      siteLink: "https://www.udemy.com/",
      certLink:
        "https://www.udemy.com/certificate/UC-90386567-0db0-4212-8917-94008ab28d2d/",
    },
    {
      organisation: "Udemy",
      course: "Figma UI UX Design Essentials",
      
      icon: <SiUdemy />,
      siteLink: "https://www.udemy.com/",
      certLink:
        "https://www.udemy.com/certificate/UC-eb250a85-3b9a-4214-a83c-2f330e94af98/?utm_source=sendgrid.com&utm_medium=email&utm_campaign=email",
    },
  ];

  const items = qualifications.map((qualification, index) => (
    <motion.div
      className={classes.row}
      key={`qualificationRow${index}`}
      initial={{ opacity: 0, y: 500 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{x: 1000}}
      transition={{ type: "spring", delay: index / 10, duration: 1.5 }}
    >
      <a
        className={classes.org}
        href={qualification.siteLink}
        target="_blank"
        rel="noreferrer"
      >
        <div className={classes.icon}>{qualification.icon}</div>
        <h2>{qualification.organisation}</h2>
      </a>

      <div className={classes.course}>
        <p>{qualification.course}</p>
      </div>
      <div className={classes.certificate}>
        <a href={qualification.certLink} target="_blank" rel="noreferrer">
          <GrCertificate />
        </a>
      </div>
    </motion.div>
  ));

  return (
    <div className={classes.container}>
     {items}
    </div>
  );
};

export default Qualifications;
