import { motion } from "framer-motion";

import classes from "./Overlay.module.css";

const Overlay = (props) => {
  return (
    <motion.div
      className={classes.overlay}
      onClick={props.modalHandler}
      initial={{ opacity: 0 }}
      animate={{ opacity: .7 }}
      exit={{ opacity: 0 }}
      transition={{duration: .3}}
    ></motion.div>
  );
};

export default Overlay;
