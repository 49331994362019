import { motion } from "framer-motion";
import classes from "./Modal.module.css";

const Modal = (props) => {
  return (
    <motion.div
      className={classes.modal}
      onClick={props.modalHandler}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className={classes.topCorner}
        initial={{ x: -200 }}
        animate={{ x: 0, transition: { type: "spring", bounce: 0.2 } }}
      ></motion.div>
      <motion.div
        className={classes.bottomCorner}
        initial={{ x: 200 }}
        animate={{ x: 0, transition: { type: "spring", bounce: 0.2 } }}
      ></motion.div>

      <p>Do you want to download Derek-de-Goey-CV-2023.pdf?</p>
      <div className={classes.buttons}>
        <motion.span
          onClick={props.modalHandler}
          whileHover={{
            backgroundColor: "var(--background)",
            color: "var(--off-white)",
            borderColor: "var(--shade)",
            transition: { duration: 0.2 },
          }}
        >
          Cancel
        </motion.span>
        <a
          href="/Derek-de-Goey-CV-2023.pdf"
          rel="noopener noreferrer"
          target="_blank"
          download
        >
          <motion.span
            onClick={props.modalHandler}
            whileHover={{
              backgroundColor: "var(--background)",
              color: "var(--off-white)",
              borderColor: "var(--shade)",
              transition: { duration: 0.2 },
            }}
          >
            Download
          </motion.span>
        </a>
      </div>
    </motion.div>
  );
};

export default Modal;
