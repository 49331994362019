import { motion } from "framer-motion";

import classes from "./Logo.module.css";

const Logo = (props) => {
  return (
    <div className={classes.maxWidth}>
      <motion.div
        className={classes.logo}
        onClick={props.onClickHandler}
        whileTap={{ scale: 1 }}
        whileHover={{scale: 1.05}}
      >
        <div className={classes.wrapper}>
          <motion.span
            initial={{ y: "120%" }}
            animate={{ y: 0 }}
            transition={{ duration: 2, type: "spring" }}
          >
            Derek
          </motion.span>
        </div>
        <div className={classes.wrapper}>
          <motion.span
            initial={{ y: "120%" }}
            animate={{ y: 0 }}
            transition={{ duration: 2, type: "spring", delay: 0.2 }}
          >
            de Goey
          </motion.span>
        </div>
        <div className={classes.wrapper}>
          <motion.span
            initial={{ y: "120%" }}
            animate={{ y: 0 }}
            transition={{ duration: 2, type: "spring", delay: 0.4 }}
          >
            co.uk
          </motion.span>
        </div>
      </motion.div>
    </div>
  );
};

export default Logo;
