import { motion } from "framer-motion";

import classes from "./Intro.module.css";

const Intro = () => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.text}>
          <motion.span
            className={classes.name}
            initial={{ y: "120%" }}
            animate={{ y: 0 }}
            transition={{ duration: 1.5, type: "spring", delay: 0.5 }}
          >
            Derek de Goey
          </motion.span>
          <motion.div
            className={classes.divider}
            initial={{ height: 0 }}
            animate={{ height: "4rem" }}
            transition={{ delay: 1 }}
          ></motion.div>
          <motion.span
            className={classes.portfolio}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1.2 }}
          >
            Portfolio
          </motion.span>
        </div>
        <motion.div
          className={classes.colorChangeWhite}
          initial={{ height: 0 }}
          animate={{ height: "100vh" }}
          transition={{
            delay: 2,
            duration: 0.5,
            ease: [0.63, 0.25, 0.83, 0.67],
          }}
        ></motion.div>
        <motion.div
          className={classes.colorChangeBlack}
          initial={{ y: "100vh"}}
          animate={{ y: 0 }}
          transition={{
            delay: 3,
            duration: 0.5,
            ease: [0.63, 0.25, 0.83, 0.67],
          }}
        ></motion.div>
      </div>
    </>
  );
};

export default Intro;
